import React, { useState, useEffect } from "react";
import {API} from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useProfileContext} from "../lib/contextLib";
import { useAlertContext } from "../lib/contextLib";
import { useGlobalLoaderContext } from "../lib/contextLib";


import {  useFormik } from 'formik';
import * as Yup from 'yup';

import { BSButton } from "../components/Buttons";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  HStack,
} from "@chakra-ui/react";
import {DatePicker} from "react-rainbow-components";

import "./DrunkardMain.css"

export const validation_scheme =  {
    "firstName": {"required": true, "min": 2, init_val: ""},
    "lastName": {"required": false, "min": 2, init_val: ""},
    "drunkardName": {"required": true, "min": 3, init_val: ""},
    "favoriteDrink": {"required": true, "min": 3, init_val: ""},
    "birthdate": {"required": true, "min": 3, init_val: new Date()},
    "email": {"required": true, "min": 3, init_val: ""},
    //"phoneNumber": {"required": false, "min": 3, init_val: ""},
    "totalCount": {"required": false, init_val: 0},
    "emailNotification": {"required": false, init_val: true},
    //"unauthentry": {"required": false, init_val: "null"}
}

export default function DrunkardMain() {

    const {profile, setProfile} = useProfileContext();
    const { setIsFullyLoading, setLoadingText} = useGlobalLoaderContext();
    const navigate = useNavigate();
    const [response, setResponse] = useState(null);
    const {onOpen} = useAlertContext();

    const [isLoading, setIsLoading] = useState(false);

    const get_validation_object = () => {
        const transformedObject = Object.keys(validation_scheme).reduce((result, key) => {
            if (validation_scheme[key].required){
                if (key === "birthdate"){
                    result[key] = Yup.date()
                    .required('Birthdate is required')
                    .test('is-at-least-16', 'Du musst mindestens 16 Jahre alt sein', function (value) {
                      // Calculate age based on the provided birthdate
                      const birthdate = new Date(value);
                      const today = new Date();
                      const age = today.getFullYear() - birthdate.getFullYear();
                      // Adjust age based on the current date and birthdate
                      const birthdateThisYear = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
                      const isBirthdayPassed = today >= birthdateThisYear;
                      const isAtLeast16 = age > 16 || (age === 16 && isBirthdayPassed);
                      return isAtLeast16;
                    })
                } else if (key ==="email") {
                    result[key] = Yup.string().email("Ungültig").required("Benötigt");
                } else if (key === "phoneNumber") {
                    result[key] = Yup.string().matches(/^[0-9]{10}$/, 'Ungültige Nummer')
                } else {
                    result[key] = Yup.string().required("Required").min(validation_scheme[key].min, "Must be at least "+validation_scheme[key].min + " characters");
                }
            }
            return result;
          }, {});
        return transformedObject;
    }

    useEffect(() => {
        async function onLoad() {
            setIsFullyLoading(true);
            setLoadingText("Lade Profil");
            setIsFullyLoading(false);
            setLoadingText("");
        }
        onLoad();
    }, [profile]);


    useEffect( () => {
        if (response!=null){
            if (response.type ==="failed"){
                onOpen(response.type, response.message );
                formik.resetForm();
            }
        }
      }, [response])

    async function profilePut(values){
        const updateProfileDict = {
            "content": {...values}
        }
        try {
            const returned = await API.post("bierstriche", "/drunkards", {body: updateProfileDict})
            return ({type: "success", content: returned["putData"], message: "Profil erfolgreich aktualisiert"})
        } catch (error) {
            formik.setErrors({apiError: 'Error occured in profilePut'})
            return ({type: "failed", content: {}, message: "Backend submission failed, try again (later)."})
        }
    }

    async function handleSubmitData(values) {

        if (formik.dirty){
            setIsLoading(true)
            const tmp_response = await profilePut(values);
            setResponse(tmp_response);
            if (tmp_response.type == "success") {
                localStorage.setItem("profile", JSON.stringify(tmp_response["content"]))
                setProfile(tmp_response["content"]);
                console.log(tmp_response["content"]);
                navigate("/");
            }
            setIsLoading(false);
            formik.resetForm();
        } else {
            navigate("/");
        }
    }

    function set_formik_init() {
        const tmp_formikInit = Object.keys(validation_scheme).reduce((result, key) => {
            result[key] = validation_scheme[key].init_val;
            return result;
          }, {});
              // override any available profile information
        for (const key of Object.keys(validation_scheme)) {
            if (key in profile){
                tmp_formikInit[key] = profile[key];
            }
        }
        return(tmp_formikInit)
    }

    const formik = useFormik({
        initialValues: set_formik_init(),
        onSubmit: async (values) => {
          await handleSubmitData(values);
        },
        validationSchema: Yup.object(get_validation_object()),
      });


    return(
        <div className="Profile">
            <div className="profileForm" >
                <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName}>
                                <FormLabel htmlFor="firstName">Vorname</FormLabel>
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.lastName && formik.errors.lastName}>
                                <FormLabel htmlFor="firstName">Nachname</FormLabel>
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.drunkardName && formik.errors.drunkardName}>
                                <FormLabel htmlFor="firstName">Dein Name als Trunkenbold</FormLabel>
                                <Input
                                    id="drunkardName"
                                    name="drunkardName"
                                    value={formik.values.drunkardName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.drunkardName}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.favoriteDrink && formik.errors.favoriteDrink}>
                                <FormLabel htmlFor="favoriteDrink">Dein Lieblingsgetränk</FormLabel>
                                <Input
                                    id="favoriteDrink"
                                    name="favoriteDrink"
                                    value={formik.values.favoriteDrink}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.favoriteDrink}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.birthdate && formik.errors.birthdate}>
                                <FormLabel>Geburtstag</FormLabel>
                                <DatePicker
                                    id="birthdate"
                                    name="birthdate"
                                    onChange={date => {formik.setFieldValue('birthdate', date); formik.validateField('birthdate');}}
                                    onBlur={date => {formik.validateField('birthdate')}}
                                    showYearDropdown
                                    dateFormat="MM/dd/yyyy"
                                    popperPlacement="bottom-start"
                                    value={formik.values.birthdate}
                                    locale="de-DE"
                                />
                                <FormErrorMessage>{formik.errors.birthdate}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                                <FormLabel htmlFor="email">Email Address</FormLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                            </FormControl>
                        </div>
                        <div className="profileRow">
                            <FormControl>
                                <HStack>
                                        <Switch 
                                            colorScheme="teal" 
                                            size="lg" 
                                            id="emailNotification"
                                            name="emailNotification"
                                            isChecked={formik.values.emailNotification}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />

                                        <FormLabel>Emailbenachrichtigung bei Bierstrich</FormLabel>
                                </HStack>

                            </FormControl>
                        </div>
                        
                
                <div align="bottom" style={{ marginTop: 'auto' }}>
                            <BSButton type="submit" width="full" isLoading={isLoading} style={{fontWeight: "bold"}} >
                                {formik.dirty ? "Speichern" : "Zurück" }
                            </BSButton>
                        </div>
                </form>
            </div>
        </div>
    )
}