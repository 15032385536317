import React, {useState} from 'react';


import "../components/Header.css"
import {dateTimeView} from "./Helpers";

import { VStack, Center, Box, Spacer, Flex, Divider, Grid, GridItem, Text, Input, IconButton, InputRightElement, InputGroup } from '@chakra-ui/react';
import { IoCloseOutline } from "react-icons/io5";
import { Mark5Icon, Mark4Icon, Mark3Icon, Mark2Icon, Mark1Icon } from "../icons/BierstrichIcons";

export function ListIconFromNum({number, size = "20px", ...props}){
    const renderComponents = (component, count) => {
        return Array.from({ length: count }, (_, index) => (
          <div key={index}>{component}</div>
        ));
      };

    const iconDict = {
        5: <Mark5Icon size={size}/>,
        4: <Mark4Icon size={size}/>,
        3: <Mark3Icon size={size}/>,
        2: <Mark2Icon size={size}/>,
        1: <Mark1Icon size={size}/>
    }

    let this_rem = number;
    let this_n;
    const n_dict = {};
    for (let i=5; i>0; i--){
      this_n = Math.floor(this_rem / i);
      this_rem = this_rem % i;
      n_dict[i] = this_n
    }
    return( 
        <>
            <Flex style={{ flexWrap: 'wrap' }}>
            {Object.keys(n_dict).sort((a,b) => b-a).map((this_n) => (
                <React.Fragment key={this_n}> 
                   {renderComponents(iconDict[this_n], n_dict[this_n])}
                </React.Fragment>
            ))}
            </Flex>
        </>
    );
  }

export function BierstrichListComponent({bierstrich, adminView,
    participantData = {firstName: "", lastName: "", drunkardName: ""},
    ...props}) {
 
    return(
        <div>
        <Divider mb={4} mt={0}/>
          <VStack spacing={2} textStyle='bierstrichText' pl={4} pr={4}>
            <Flex width="full">
                <Text mb={0}>
                    {dateTimeView(bierstrich.caseDate, true)}
                </Text>
                <Spacer/>
                <Center width="30%">
                    <Flex ml='auto'><ListIconFromNum number={bierstrich.count} size="20px"/></Flex>
                    {/* bierstrich.count > 5 ?
                        <Flex ml='auto'> <Text mb={0}>{bierstrich.count} X </Text><Center ml={1} ><IoBeerOutline/></Center></Flex>
                        :
                        <Center ml='auto'>
                        {[...Array(bierstrich.count)].map((e,i)=><IoBeerOutline/>)}
                        </Center>
                    */}
                </Center>
            </Flex>
            <Grid
                      templateRows='repeat(2, 1fr)'
                      templateColumns='repeat(3,1fr)'
                      width="full"
            >
                {adminView ?<>
                    <GridItem colSpan={1}>
                        <Text mb={0}>
                            Für:
                        </Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Text mb={0}>
                        {`${participantData.drunkardName} (${participantData.firstName} ${participantData.lastName})`}
                        </Text>
                    </GridItem>
                </>:<></>}
                <GridItem colSpan={1}>
                    <Text mb={0}>
                        Grund:
                    </Text>
                </GridItem>
                <GridItem colSpan={2}>
                    <Text mb={0}>
                        {bierstrich.reason}
                    </Text>
                </GridItem>
                <GridItem colSpan={1}>
                    Busted von:
                </GridItem>
                <GridItem colSpan={2}>
                    <Text mb={0}>
                    {props.policeName}
                    </Text>
                </GridItem>
            </Grid>
        </VStack>
        
    </div>
    )

}


export default function BierstrichList({
    mylist,
    drunkardData,
    fullParticipantData,
    adminView=false,
    ...props}){


    const [searchTerm, setSearchTerm] = useState("");
    const clearSearch = () => {
        setSearchTerm('');
      };

      function getPoliceName(bierstrich, drunkardData) {
        try {
            const policeId = bierstrich["policeId"];
            return drunkardData[policeId].drunkardName;
        } catch (error) {
            return "oops";
        }
    }

    function getParticipantData(bierstrich, fullParticipantData){
        const founds = fullParticipantData.find(item => item.userId === bierstrich.userId) || {}
        return({
            firstName: founds.firstName,
            lastName: founds.lastName, 
            drunkardName: founds.drunkardName,
        })
    }

    const mylist_extended = mylist.map((item, index) => ({
        ...item,
        policeName: getPoliceName(item, drunkardData),
        participantData: getParticipantData(item, fullParticipantData)
    }))

    function getFilteredList(mylist_extended, searchTerm, adminView) {
        if (adminView) {
            return mylist_extended.filter(item => 
                item.participantData.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                item.participantData.lastName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                (item.participantData.firstName + " " + item.participantData.lastName).toLowerCase().includes(searchTerm.toLowerCase()) || 
                item.participantData.drunkardName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.policeName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return mylist_extended;
        }
    }

    const filteredList = getFilteredList(mylist_extended, searchTerm, adminView);

    return(
        <Box>
          <VStack spacing={0} alignItems="justify">
            {props.includeSearch ? <>
            <InputGroup>
            <Input
                placeholder="Liste durchsuchen"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <InputRightElement>
            {searchTerm && (
            <IconButton
                aria-label="Clear Search"
                icon={<IoCloseOutline />}
                onClick={clearSearch}
                bg="white"
                size="90%"
                mt={1} mb={1} mr={1}
            />
            )}
            </InputRightElement>
            </InputGroup>
            </>:<></>}
            {filteredList.map((item, iter) => (
                <div key={item.bierId}>
                    <BierstrichListComponent 
                        bierstrich={item} 
                        policeName={getPoliceName(item, drunkardData)}
                        participantData={getParticipantData(item, fullParticipantData)}
                        adminView={adminView}
                        {...props}
                    />
                </div>
             ))
            }
          </VStack></Box>
    )
}





