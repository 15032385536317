import { Heading, HStack, Center, Divider, Button, Flex, Spacer, Card, Text, VStack, Box, CardHeader, CardBody, Spinner, Grid,GridItem  } from "@chakra-ui/react";

import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom'
//import { FaCrown, FaBeer } from "react-icons/fa";
import { IoBeerOutline, IoPersonOutline, IoPeopleOutline, IoTrophyOutline, IoReturnDownForwardOutline} from "react-icons/io5";
import { Mark5Icon, IoBeerCrossedOutline } from "../icons/BierstrichIcons";
import "./Cards.css"
import {loadEvent, updateEventInEvents} from "../lib/loadingLib";
import { useProfileContext } from "../lib/contextLib";
import { ListIconFromNum } from "./BierstrichLists";
import {  DButton } from "./Buttons";
import { dateTimeView } from "./Helpers";



const get_outdated = (eventenddatestring) => {
  const currentDate = new Date();
  const endDate = new Date(eventenddatestring);
  return endDate < currentDate;
}


export const LinkerCardChakra = ({linkto, title, ...props}) => {
  return <Link 
      to={linkto}
      className="cardLink"
    > 
    <Card bg="yellow.100" className="linkerCard" variant="elevated" >
      <CardHeader fontSize='24px' fontWeight="bold">{title}</CardHeader>
      <CardBody fontSize="16px">
        {props.children}
      </CardBody>
    </Card>
</Link>
}



export const LinkedEventCard = ({event, myuuid, ...props}) => {
  const linktarget = "/events/" + event.eventId;
  return <Link 
      to={linktarget}
      className="cardLink"
    >
      <EventCard 
        myuuid={myuuid}
        event={event}
      />
    </Link> 
}

export const EventStatsGrid = ({event, myuuid="", applied=true, handleApply=() => {}, ...props}) => {
  const ownbs= isNaN(event.ownEventCount) ? 0 : event.ownEventCount;
  const ownredeemed= isNaN(event.ownEventRedeemed) ? 0 : event.ownEventRedeemed;
  const getKingText = (indict) => {
    let init_text;
    if (Object.keys(indict).length === 0) {
      init_text = "Wirst du der nächste König sein?"
    } else {
      init_text = Object.keys(indict)[0]
    } 
    for (let i=1; i< Object.keys(indict).length; i++) {
      init_text += ", "+Object.keys(indict)[i];
    }
    return init_text;
  }

  const shiningEffect = {
    animation: "shining 1.5s infinite alternate",
    boxShadow: "0 0 10px rgba(0, 0, 255, 0.7)", // Adjust the color and intensity of the glow
  };
  const totEvent = event.totalCount;
  const totRedeemed = event.totalRedeemed;
  const totOpen = totEvent - totRedeemed;
  return(
      <Grid
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(3,1fr)'
          gap={4}
        >
          <GridItem colSpan={1} pt={2} pb={2}>
            <VStack spacing={0} alignItems="justify">
              <Center><Text mb={0}>Alle</Text></Center>
              <Center flexDir="column">
              <Flex><IoPeopleOutline size="40px"/><IoPeopleOutline size="40px"/></Flex>
              </Center>
              <Flex>
                  <Mark5Icon size="20px"/>
                  <Spacer/>
                  <Text mb={0}>{totEvent}</Text> 
              </Flex>
              <Flex>
                  <IoBeerCrossedOutline size="20px"/>
                  <Spacer/>
                  <Text mb={0}>{totRedeemed}</Text>
              </Flex>
              <Flex>
              <IoBeerOutline size="20px"/>
                  <Spacer/>
                  <Text mb={0}
                    color={totOpen <= 0 ? "green" :  "red"}
                  >{totOpen}</Text>
              </Flex>
            </VStack>
          </GridItem>
          <GridItem colSpan={1} style={shiningEffect} pt={2} pb={2}>
            <Center flexDirection="column" >
              <Center mb={4}>
              <IoTrophyOutline size="40px" mb="20px"/>
              </Center>
              {Object.keys(event["kings"]).length > 0 ? <>
                    <HStack textAlign="center">
                      <Text mb={0}>{getKingText(event["kings"])}</Text>
                    </HStack>
                <ListIconFromNum size="20px" number={event["kings"][Object.keys(event["kings"])[0]]}/>
              </>: <>
                <Center textAlign="center"><Text>Wirst du der nächste König?</Text></Center>
              </>}
            </Center>
          </GridItem>
          <GridItem colSpan={1} pt={2} pb={2}>
          {applied ? 
            <VStack spacing={0} alignItems="justify">
              <Center>
                <Text mb={0}>Du</Text>
              </Center>
                <Center flexDir="column">
                  <IoPersonOutline size="40px"/>
                </Center>
                <Flex>
                  <Mark5Icon size="20px"/> 
                  <Spacer/>
                  <Text mb={0}>{ownbs}</Text> 
                </Flex>
                <Flex>
                  <IoBeerCrossedOutline size="20px"/>
                      <Spacer/>
                      <Text mb={0}>{ownredeemed}</Text>
                  </Flex>
                  <Flex>
                  <IoBeerOutline size="20px"/>
                      <Spacer/>
                      <Text mb={0}
                        color={ownbs - ownredeemed <= 0 ? "green" :  "red"}
                      >{ownbs - ownredeemed}</Text>
                </Flex>
              </VStack>
            : <>
            <DButton
              onClick={() => handleApply()}
              isLoading={props.isLoading}
              isDisabled={props.disabled}
              width="100%"
              h="100%"
              
            > 
              <VStack textStyle="cardText">
                <Text >Teilnehmen</Text>
                <Center><IoReturnDownForwardOutline size="30px" /><Mark5Icon size="20px"/><IoBeerOutline size="20px"/></Center>
              </VStack>
              
            </DButton>
            
            </>}
          </GridItem>
        </Grid>

  )
}

export const EventCardBodyPublic = ({event, handleApply, ...props}) => {
  return(
    <Box  
      textStyle='cardText'
    >
      <EventStatsGrid
        event={event}
        applied={false}
        handleApply={handleApply}
        {...props}
      />
        {(event.hasOwnProperty("eventDescription")) ? 
          <>
            <Divider/>
            <Box >
              <Text>{event.eventDescription}</Text>
            </Box> 
          </>
        : <></>}
        </Box>  
  )
}

export const EventCardBody = ({ event, myuuid }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight, clientHeight } = textRef.current;
      if (scrollHeight > clientHeight) {
        setShowButton(true);
      }
    }
  }, [event.eventDescription]);

  return (
    <Box textStyle='cardText'>
      <EventStatsGrid event={event} myuuid={myuuid} />
      {event.hasOwnProperty("eventDescription") && (
        <>
          <Divider />
          <Box>
            <Text
              ref={textRef}
              noOfLines={isExpanded ? null : 3}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {event.eventDescription }
            </Text>
            {showButton && (
              <Button size="sm" onClick={toggleExpand} variant="link">
                {isExpanded ? "Weniger anzeigen" : "Mehr anzeigen"}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export const EventCard = ({ event, myuuid, ...props}) => {
  useEffect( () => {
  }, []);

  return (
    <Card
      bg={(get_outdated(event.endDate) ? "yellow.50": "yellow.100")} 
      className="linkerCard" 
      variant="elevated" 
      height="280px"
      overflow="hidden"
      overlfow="auto"
      {...props}
      boxShadow='lg'
    >
      <CardHeader p={4}
        textStyle='cardText'
      >
        <VStack alignItems="left" spacing={0}>
        <Flex>
          <Heading size="md">{event.eventName}</Heading>
          {event.isAdmin ? <>
        <Spacer/>
          <Heading as="span" size="md" fontWeight="normal" fontStyle="italic">Admin</Heading>
        </>:<></>}
        </Flex>  
          <Text mb={0}>{dateTimeView(event.startDate, false)} bis {dateTimeView(event.endDate, false)}</Text>
        </VStack>
      </CardHeader>
      <Divider m={0}/>
      <CardBody fontSize="16px" p={4}>
        <EventCardBody 
          event={event}
          myuuid={myuuid}
        />

      </CardBody>
    </Card>
  )
}

export const EventCardPublic = ({event, isLoading, disabled, handleApply, ...props }) => {
  return (
    <Card
      bg={("yellow.50")} 
      className="linkerCard" 
      variant="elevated" 
      height="280px"
      overflow="hidden"
      overlfow="auto"
      {...props}
      boxShadow='lg'
    >
      <CardHeader p={4}
        textStyle='cardText'
      >
        <Center>
          <VStack alignItems="left" spacing={0}>
            <Text mb={0}>{dateTimeView(event.startDate, false)} bis {dateTimeView(event.endDate, false)}</Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider m={0}/>
      <CardBody>
        <EventCardBodyPublic
            event={event}
            isLoading={isLoading}
            disabled={disabled}
            handleApply={handleApply}
        />
      </CardBody>
    </Card>
  )
}

export const EventCardApplied = ({ event, myuuid, ...props}) => {
  const {events, setEvents} = useProfileContext();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    console.log("clicked", event.eventId)

    const eventIdStr = String(event.eventId);
    const reloadedEvent = await loadEvent(event.eventId);
    console.log(reloadedEvent);
    const accepted = reloadedEvent["accepted"];
    console.log(accepted);
    console.log(eventIdStr);
    if (accepted) {
      console.log("you are now accepted")
      const updated_events = updateEventInEvents(events, eventIdStr, reloadedEvent);
      /*const updated_events = {
        ...events,
        [eventIdStr]: reloadedEvent
      }*/
      console.log(updated_events);
      localStorage.setItem('events', JSON.stringify(updated_events));
      setEvents(updated_events);
    }
    setLoading(false);
  }

  return (
    <Card 
      astype={<Button/>} 
      onClick={handleClick} // work as button
      cursor="pointer" // behave as button
      bg={get_outdated(event.endDate) ? "yellow.50": "yellow.100"} 
      className="linkerCard" 
      variant="elevated" 
    >
      <CardHeader textStyle='cardText' p={4}>
        <Flex>
        <VStack alignItems="left" spacing={0}>
          <Heading size="md">{event.eventName}</Heading>
          <Text mb={0}>{dateTimeView(event.startDate, false)} bis {dateTimeView(event.endDate, false)}</Text>
        </VStack>
        </Flex>  
      </CardHeader>
      <Divider m={0}/>
      <CardBody>
        {loading ? 
        <Center><Spinner size="xl"/></Center>
        : 
          <Box>
            <Center>
              <Text as="span" fontSize="md">Du bist zum Event angemeldet, musst vom Admin aber noch zugelassen werden. (Tap for refresh)</Text>
            </Center>
          </Box>
        }
      </CardBody>
    </Card>
  )
}


