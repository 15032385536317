import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {BSButton} from "../components/Buttons";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import {StaticBierstrichText} from "../components/Header"
import { useNavigate } from "react-router-dom";
import {  useFormik } from 'formik';
import * as Yup from 'yup';

//import "./Login.css";
import "./DrunkardMain.css";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Center
} from "@chakra-ui/react";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  async function handleSubmit(values) {
    setIsLoading(true);

    try {
      await Auth.signIn(values.email, values.password);
      userHasAuthenticated(true);
      navigate("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
    validationSchema: Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("Required"),
    }),
  });

  return (
    <div className="Login">
      <StaticBierstrichText>
        Willkommen bei
      </StaticBierstrichText>
      <div className="profileForm">
      <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                  <FormLabel htmlFor="eventName">Passwort</FormLabel>
                  <Input
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="password"
                  />
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <BSButton 
                  type="submit" 
                  width="full" 
                  isLoading={isLoading} 
                  style={{fontWeight: "bold"}} 
                  disabled={!formik.isValid}
                  >
                  {"Login"}
              </BSButton>
          </div>
      </form>
      <Center><Link to="/login/reset">Passwort vergessen?</Link></Center>
      <Center>oder</Center>
      <Center><Link to="/signup">Hier anmelden!</Link></Center>
      </div>
    </div>
  );
}


