import React, { useContext, createContext, useState } from "react";

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}


export const ProfileContext = createContext();
/*export const ProfileProvider = ({children}) => {
    const [profile, setProfile] = useState({});
    const [bierstriche, setBierstriche] = useState([]);
    const [events, setEvents] = useState([]);
        return (
            <ProfileContext.Provider value={{profile, setProfile, bierstriche, setBierstriche, events, setEvents}}>
                {children}
            </ProfileContext.Provider>
        )
}*/
export function useProfileContext() {
    return useContext(ProfileContext);
}



export const EventContext = createContext(null);
export const EventProvider = ({children}) => {
    const [contextEvent, setContextEvent] = useState({});
    const [eventParticipants, setEventParticipants ] = useState({});
    const [eventBierstriche, setEventBierstriche] = useState({});
        return (
            <EventContext.Provider value={{contextEvent, setContextEvent, eventParticipants, setEventParticipants, eventBierstriche, setEventBierstriche}}>
                {children}
            </EventContext.Provider>
        )
}
export function useEventContext() {
    return useContext(EventContext);
}


export const GlobalLoaderContext = createContext(null);
export const GlobalLoaderProvider = ({children}) => {
    const [isFullyLoading, setIsFullyLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    return (
        <GlobalLoaderContext.Provider value={{isFullyLoading, setIsFullyLoading, loadingText, setLoadingText}}>
            {children}
        </GlobalLoaderContext.Provider>
    )
}
export function useGlobalLoaderContext() {
    return useContext(GlobalLoaderContext);
}


const AlertContext = createContext(undefined);

export const AlertProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    // Type can be either "success" or "error"
    type: 'success',
    // Message to be displayed, can be any string
    message: '',
  });

  return (
    <AlertContext.Provider
      value={{
        ...state,
        onOpen: (type, message) => setState({ isOpen: true, type, message }),
        onClose: () => setState({ isOpen: false, type: '', message: '' }),
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);