
export function dateTimeView(datestring, includeTime=true){
    const options={ year: 'numeric', month: 'short', day: 'numeric' }
    const locale = "de-DE"
    const date = new Date(datestring).toLocaleDateString(locale, options)
    if (includeTime){
        const time = new Date(datestring).toTimeString().slice(0,8)
        return `${date}, ${time}`
    } else {
        return `${date}`
    }

    /*const this_date = new Date(datestring);
    const year = this_date.getFullYear();
    const month = this_date.getMonth();
    const date = this_date.getDate();
    const day = dayNameDict[this_date.getDate()];
    return `${day}, ${date}.${month}.${year}  `*/

}
