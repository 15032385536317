import Mark5 from "./mark5.png"
import Mark4 from "./mark4.png"
import Mark3 from "./mark3.png"
import Mark2 from "./mark2.png"
import Mark1 from "./mark1.png"
import BCO from "./iobeercrossedoutline.png"


export const IoBeerCrossedOutline = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={BCO} alt="mark 5" style={{width: size, height: size}} {...props}/>
        </div>
    )
}


export const Mark5Icon = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={Mark5} alt="mark 5" style={{width: size, height: size}} {...props}/>
        </div>
    )
}



export const Mark4Icon = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={Mark4} alt="mark 4" style={{width: size, height: size}} {...props}/>
        </div>
    )
}


export const Mark3Icon = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={Mark3} alt="mark 3" style={{width: size, height: size}} {...props}/>
        </div>
    )
}


export const Mark2Icon = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={Mark2} alt="mark 2" style={{width: size, height: size}} {...props}/>
        </div>
    )
}


export const Mark1Icon = ({
    size='15px',
    ...props
    }) => {
    return (
        <div>
            <img src={Mark1} alt="mark 1" style={{width: size, height: size}} {...props}/>
        </div>
    )
}
