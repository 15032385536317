import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {BSButton} from "../components/Buttons";
import {StaticBierstrichText} from "../components/Header"
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./Signup.css";
import { Auth } from "aws-amplify";

import {  useFormik } from 'formik';
import * as Yup from 'yup';

//import "./Login.css";
import "./DrunkardMain.css";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Checkbox
} from "@chakra-ui/react";


import {PopNotice} from "../components/Popups"
export default function Signup() {
  const [userValues, setUserValues] = useState({email: "", password: ""});
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptDataPolicy, setAcceptDataPolicy] = useState(false);
  const [reenterConfirmation, setReenterConfirmation] = useState(false);
  const [showConfirmationCodeSent, setShowConfirmationCodeSent] = useState(false);



  async function handleSubmit(values) {
    console.log("in handle submit")
    console.log(values)
        setIsLoading(true);
        setUserValues(values);
        try {
          const newUser = await Auth.signUp({
            username: values.email,
            password: values.password,
          });
          console.log("in handle submit after new user")
          console.log(newUser)
          setShowConfirmationCodeSent(true)
          setNewUser(newUser);
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
        setIsLoading(false);
  }

  async function handleResendConfirmationCode(values) {
    console.log("In resending code");
    try {
        await Auth.resendSignUp(values.email);
        setShowConfirmationCodeSent(true)
        //console.log(`code resent successfully to ${userValues.email}`);
    } catch (err) {
        console.log('error resending code: ', err);
        alert(err)
    }
  }

  async function handleConfirmationSubmit(values) {
    setIsLoading(true);
    /*event.preventDefault();
    const form = event.currentTarget;
    console.log(form.checkValidity())
    if (form.checkValidity() === false){
        event.stopPropagation();
        setValidated(true);
    } else{*/
        try {
          
          await Auth.confirmSignUp(values.email, values.confCode);
          await Auth.signIn(values.email, values.password);
          
          userHasAuthenticated(true);
          navigate("/");
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
    //}
  }

  function handleSetAcceptTerms(){
    setAcceptTerms(!acceptTerms)
  }

  function handleSetAcceptDataPolicy(){
    setAcceptDataPolicy(!acceptDataPolicy)
  }

  function RenderConfirmationForm() {
    const formik = useFormik({
      initialValues: {
        confCode: "",
      },
      onSubmit: async (values) => {
        await handleConfirmationSubmit({...userValues, confCode: values.confCode});
      },
      validationSchema: Yup.object({
          confCode: Yup.string().required("Required"),
      }),
    });

    return (<>
      <div className="profileForm">
      <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.code && formik.errors.code}>
                  <FormLabel htmlFor="confCode">Confirmation Code</FormLabel>
                  <Input
                      id="confCode"
                      name="confCode"
                      type="confCode"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <BSButton 
                  type="submit" 
                  width="full" 
                  isLoading={isLoading} 
                  style={{fontWeight: "bold"}} 
                  disabled={!formik.isValid}
                  >
                  {"Verify"}
              </BSButton>
          </div>
          <div className="profileRow">
              <BSButton 
              width="full" 
              isLoading={isLoading} 
              style={{fontWeight: "bold"}} 
              disabled={false}
              onClick = {() => handleResendConfirmationCode(userValues)}
            >
              Request new validation code
            </BSButton>
          </div>
        </form>
        </div>
      </>

    );
  }

  function RenderForm() {
    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
        passwordconf: "",
        accterms: false,
        accdp: false
      },
      onSubmit: async (values) => {
        await handleSubmit(values);
      },
      validationSchema: Yup.object({
          email: Yup.string().email("Invalid email address").required("Required"),
          password: Yup.string().required("Required"),
          passwordconf: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Required")
      }),
    });
    return (<>
    <div className="profileForm">
      <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                  <FormLabel htmlFor="eventName">Passwort</FormLabel>
                  <Input
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="password"
                  />
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.passwordconf && formik.errors.passwordconf}>
                  <FormLabel htmlFor="eventName">Passwort bestätigen</FormLabel>
                  <Input
                      id="passwordconf"
                      name="passwordconf"
                      value={formik.values.passwordconf}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="password"
                  />
                  <FormErrorMessage>{formik.errors.passwordconf}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.accterms && formik.errors.accterms}>
                  <Checkbox
                      ml={4}
                      id="accterms"
                      name="accterms"
                      value={formik.values.accterms}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  >Accept terms</Checkbox>
                  <FormErrorMessage>{formik.errors.accterms}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow" >
              <FormControl isInvalid={formik.touched.accdp && formik.errors.accdp}>
                  <Checkbox
                      ml={4}
                      id="accdp"
                      name="accdp"
                      value={formik.values.accdp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  >Accept Data Policy</Checkbox>
                  <FormErrorMessage>{formik.errors.accdp}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <BSButton 
                  type="submit" 
                  width="full" 
                  isLoading={isLoading} 
                  style={{fontWeight: "bold"}} 
                  disabled={!formik.isValid}
                  >
                  {"Signup"}
              </BSButton>
          </div>
          <div className="profileRow">
            <BSButton 
              width="full" 
              style={{fontWeight: "bold"}} 
              onClick={() => setReenterConfirmation(true)}
              isLoading={isLoading}
              disabled={false}
            >
            Enter validation code
            </BSButton>
          </div>
      </form>
      </div>

    </>
      
    );
  }

  function RenderReenterConfirmationCode(){
    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
        confCode: "",
      },
      onSubmit: async (values) => {
        await handleConfirmationSubmit(values);
      },
      validationSchema: Yup.object({
          email: Yup.string().email("Invalid email address").required("Required"),
          password: Yup.string().required("Required"),
          confCode: Yup.string().required("Required")
      }),
    });
    return(

    <div className="profileForm">
      <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                  <FormLabel htmlFor="eventName">Passwort</FormLabel>
                  <Input
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="password"
                  />
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <FormControl isInvalid={formik.touched.code && formik.errors.code}>
                  <FormLabel htmlFor="confCode">Confirmation Code</FormLabel>
                  <Input
                      id="confCode"
                      name="confCode"
                      type="confCode"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
              </FormControl>
          </div>
          <div className="profileRow">
              <BSButton 
                  type="submit" 
                  width="full" 
                  isLoading={isLoading} 
                  style={{fontWeight: "bold"}} 
                  disabled={!formik.isValid}
                  >
                  {"Verify"}
              </BSButton>
          </div>
          <div className="profileRow">
            <BSButton 
              width="full" 
              style={{fontWeight: "bold"}} 
              onClick={() => handleResendConfirmationCode(formik.values)}
              isLoading={isLoading}
              disabled={false}
            >
                Request new validation code
            </BSButton>
          </div>
      </form>
      </div>
   );
  }

  return (
    <div className="Signup">
        <PopNotice
                show={showConfirmationCodeSent}
                setShow={setShowConfirmationCodeSent}
                text={`A confirmation code was sent to your email address ${userValues.email}`}
                title="Confirmation Code"
                handleConfirm={() => setShowConfirmationCodeSent(false)}
                confirmText="Ok"
        />
      <StaticBierstrichText>
        Willkommen bei
      </StaticBierstrichText>
      { (newUser === null && reenterConfirmation === false) ?
         <RenderForm/>
      : <></>}
      { (newUser === null && reenterConfirmation === true) ?
            <RenderReenterConfirmationCode/>
       : <></>}

      {!(newUser === null) ?

      <RenderConfirmationForm/>
       : <></>}
    </div>
  );
}
