
import React, {useEffect, useState} from 'react';


import "../components/Header.css"

import { VStack, Center, Box, Spacer, Flex, Divider, Grid, GridItem, Text, Input, IconButton, InputRightElement, InputGroup } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { IoBeerOutline, IoKeyOutline, IoCloseOutline, IoBanOutline, IoPersonOutline, IoPersonAddOutline, IoPersonRemoveOutline} from "react-icons/io5";
import { Mark5Icon, IoBeerCrossedOutline } from "../icons/BierstrichIcons";
import  RedeemModal, { BierstrichModal } from './Popups';
import { BSButton, DButton } from './Buttons';


function DrunkardData({
    userProfile,
    showStats=true,
    ...props
}){
    const bs=userProfile.ownEventCount;
    const rd=userProfile.ownEventRedeemed;
    return(
        <>
            <Divider mt={0} mb={4} ml={0} mr={0} borderWidth="1px" width="99%"/>
            <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(3,1fr)'
                width="full"
                pl={4} 
                pr={4}
                gap={4}
                textStyle="bierstrichText" 
                {...props}
            >
                <GridItem colSpan={2} textAlign="left" >
                    <VStack spacing={0} alignItems="justify">
                        <Text fontWeight="bold" mb={0}>{userProfile["drunkardName"]}</Text>
                        <Text mb={0}>{userProfile["firstName"]+" " +userProfile["lastName"]}</Text>
                        <Text mb={0}>{userProfile["favoriteDrink"]}</Text>
                    </VStack>
                </GridItem>
                <GridItem colSpan={1} width={"90%"} ml='auto'>
                {showStats ? 
                <VStack spacing={0} alignItems="justify">
                    <Flex>
                        <Mark5Icon size="20px"/>  
                        <Spacer/>
                        <Text mb={0}>{bs}</Text> 
                    </Flex>
                    <Flex>
                        <IoBeerCrossedOutline size="20px"/>
                        <Spacer/>
                        <Text mb={0}>{rd}</Text>
                    </Flex>
                    <Flex>
                        <IoBeerOutline size="20px"/>
                        <Spacer/>
                        <Text mb={0}
                        color={bs - rd <= 0 ? "green" :  "red"}
                        >{bs - rd}</Text>
                    </Flex>
                </VStack> : <></>
                }
                </GridItem>
            </Grid>
        </>
    );
}

function DrunkardDataWButtons({
    userProfile,
    handleAdmin,
    isAdminLoading,
    handleExclude,
    isExcludeLoading,
    isBSLoading,
    handleBS,
    isRedeemLoading,
    handleRedeem,
    handleSelectClick,
    buttonsDisabled = false
}){
    const userId = userProfile["userId"];
    return <>
            <DrunkardData 
                userProfile={userProfile}
                as="button"
                onClick={() => handleSelectClick(userProfile)}
            />
            <Flex textStyle="cardText" alignItems='flex-end' justify="space-between" width="full" px={0} mb={0} mt={2} ml={0} mr={0}>
            <DButton
                onClick={() => handleSelectClick(userProfile)}
                >
                <input
                    type="checkbox"
                    checked={userProfile.selected}
                    onChange={() => {}}
                    disabled={false}
                />
            </DButton>
            <DButton
                isLoading={isBSLoading}
                onClick={() => handleBS(userId, true)}
                disabled={buttonsDisabled}
            ><IoBeerOutline size="20px"/><Text size="20px">+</Text></DButton>
            <DButton
                isLoading={isRedeemLoading}
                onClick={() => handleRedeem(userId)}
                disabled={buttonsDisabled}
            ><IoBeerOutline size="20px"/><Text size="20px">-</Text></DButton>
            <DButton
                isLoading={isExcludeLoading}
                onClick={() => handleExclude(userId, false)}
                disabled={buttonsDisabled}
            ><IoPersonRemoveOutline size="20px"/></DButton>
            <DButton
                bg={userProfile.isAdmin ? 'yellow.100' : '#EDF2F7'}
                borderColor={userProfile.isAdmin ? 'yellow.100' : '#EDF2F7'}
                onClick={() => handleAdmin(userId)}
                isLoading={isAdminLoading}
                disabled={buttonsDisabled}
            ><IoPersonOutline size="20px"/><IoKeyOutline size="20px"/></DButton>
            </Flex>
        </>
}

export function DrunkardsListComponent({drunkards,
    handleAdmin,
    isAdminsLoading,
    handleExclude,
    isExcludeLoading,
    isBSLoading,
    handleBS,
    isRedeemLoading,
    handleRedeem}) {

    const [searchTerm, setSearchTerm] = useState("");
    const clearSearch = () => {
        setSearchTerm('');
      };

    const filteredList = drunkards.filter(item =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.firstName + " " + item.lastName).toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.drunkardName.toLowerCase().includes(searchTerm.toLowerCase())
        );

    return (
        <>
        <Box p={4} textAlign="center">
            <Text mb={0}>Teilnehmer</Text>
        </Box>

        <VStack spacing={0} alignItems="justify">
            <InputGroup>
                <Input
                    placeholder="Liste durchsuchen"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputRightElement>
                    {searchTerm && (
                    <IconButton
                        aria-label="Clear Search"
                        icon={<IoCloseOutline />}
                        onClick={clearSearch}
                        bg="white"
                        size="90%"
                        mt={1} mb={1} mr={1}
                    />
                    )}
                </InputRightElement>
            </InputGroup>
            {filteredList.map((item, iter) => (
                <div key={item.userId}>

                    <DrunkardDataWButtons
                        userProfile={item}
                        handleAdmin={handleAdmin}
                        isAdminLoading={isAdminsLoading.hasOwnProperty(item.userId) && isAdminsLoading[item.userId]}
                        handleExclude={handleExclude}
                        isExcludeLoading={isExcludeLoading.hasOwnProperty(item.userId) && isExcludeLoading[item.userId]}
                        handleRedeem={handleRedeem}
                        isRedeemLoading={isRedeemLoading.hasOwnProperty(item.userId) && isRedeemLoading[item.userId]}
                        handleBS={handleBS}
                        isBSLoading={isBSLoading.hasOwnProperty(item.userId) && isBSLoading[item.userId]}
                    />
                </div>
            ))}
        </VStack>
        </>
        )
    ;
}


export function DrunkardsSelectListComponent({
    drunkards,
    handleBSConfirm=()=>{},
}){
    const { isOpen: isBSOpen, onOpen: onBSOpen, onClose: onBSClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState("");
    const clearSearch = () => {
        setSearchTerm('');
      };

    const [drunkardsSelected, setDrunkardsSelected] = useState(drunkards.map(item => ({...item, selected: false})));

    const filteredList = drunkardsSelected.filter(item => !item.selected).filter(item =>
            item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.firstName + " " + item.lastName).toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.drunkardName.toLowerCase().includes(searchTerm.toLowerCase())
        );

    function handleItemClick(userProfile){
        const tmp_drunkardsSelected = drunkardsSelected.map(item => {
            if (item.userId === userProfile.userId){
                return {...item, selected: !item.selected};
            } else {
                return {...item}
            }
        })
        setDrunkardsSelected(tmp_drunkardsSelected)
    }

    function handleClearSelect(){
        setDrunkardsSelected(drunkards.map(item => ({...item, selected: false})));
    }

    const maxph="100%"
    const minph="45vh"
    const maxh="100%"
    const minh="30vh"
    const [drunkardsListParentHeight, setDrunkardsListParentHeight] = useState(maxph);
    const [drunkardsListHeight, setDrunkardsListHeight] = useState(maxh);
    useEffect(() => {
        let nosel = drunkardsSelected.filter(item => item.selected).length;
        if (nosel > 0){
            setDrunkardsListParentHeight(minph);
            setDrunkardsListHeight(minh);
        } else {
            setDrunkardsListParentHeight(maxph)
            setDrunkardsListHeight(maxh);
        }
    }, [drunkardsSelected]);

    return (<>
        <Box h="100%" display="flex" flexDirection="column">
        {/* ToDo: Is this modal required here or can we use the modal in the parent? Can we, if we combine the lists, move the parent modal here / kill it in the parent? */}
        <BierstrichModal
            isOpen={isBSOpen}
            onClose={onBSClose}
            drunkard={drunkardsSelected}
            handleBS={handleBSConfirm}
            drunkards={drunkardsSelected.filter(item => item.selected)}
        />
        <Box flex={1} h={drunkardsListParentHeight} overflow="hidden">
        <Box p={4} textAlign="center">
            <Text mb={0}>Multi-Bierstrich</Text>
        </Box>
        <VStack spacing={0} alignItems="justify">
                <InputGroup>
                    <Input
                        placeholder="Liste durchsuchen"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputRightElement>
                        {searchTerm && (
                        <IconButton
                            aria-label="Clear Search"
                            icon={<IoCloseOutline />}
                            onClick={clearSearch}
                            bg="white"
                            size="90%"
                            mt={1} mb={1} mr={1}
                        />
                        )}
                    </InputRightElement>
                </InputGroup>
            </VStack>
            <VStack spacing={0} alignItems="stretch" overflowY="auto" maxH={drunkardsListHeight}>
                {filteredList.map((item, iter) => (
                    <div key={item.userId}>
                        <DrunkardData
                            userProfile={item}
                            as="button"
                            onClick={() => handleItemClick(item)}
                        />
                    </div>
                ))}
            </VStack>
        </Box>
        {drunkardsSelected.filter(item => item.selected).length > 0 ?
            <>
            <Divider align="bottom" borderWidth={2}/>  
            <Box flex={1}  position="sticky" h="45vh" top={"50%"} overflow="hidden">
                <Box p={4} textAlign="center" position="sticky" top={0} bg="white" zIndex={1}>
                <Flex p={0}>
                <Center>
                        <BSButton
                            onClick={()=>handleClearSelect()}
                        >
                            <IoCloseOutline size={20}/>
                        </BSButton>
                    </Center>
                    <Spacer/>
                    <Text mb={0}>Sippenhaft:</Text>
                    <Spacer/>
                    <Center>
                        <BSButton
                            onClick={()=>onBSOpen()}
                        >
                            <Mark5Icon size={20}/>
                        </BSButton>
                    </Center>
                </Flex>
                </Box>
                <VStack spacing={0} alignItems="stretch" overflowY="auto" maxH="30vh">
                {drunkardsSelected.filter(item => item.selected).map((item, iter) => (
                    <div key={item.userId}>
                        <DrunkardData
                            userProfile={item}
                            as="button"
                            onClick={() => handleItemClick(item)}
                        />
                    </div>
                ))}
                </VStack>
            </Box>
        </>:<></>}
        <Box ></Box>

    </Box></>);
    }


export function DrunkardsSelectListComponentButton({
    drunkards,
    handleAdmin,
    isAdminsLoading,
    handleExclude,
    isExcludeLoading,
    isBSLoading,
    isRedeemLoading,
    handleRedeemConfirm,
    handleBSConfirm=()=>{},
}){
    /* Check if we can pull isXLoading into this component*/
    const { isOpen: isBSOpen, onOpen: onBSOpen, onClose: onBSClose } = useDisclosure();
    const { isOpen: isRedeemOpen, onOpen: onRedeemOpen, onClose: onRedeemClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState("");
    const clearSearch = () => {
        setSearchTerm('');
      };

    const [drunkardsSelected, setDrunkardsSelected] = useState(drunkards.map(item => ({...item, selected: false})));
    const [drunkardsForModal, setDrunkardsForModal] = useState([]);
    const [drunkardForRedeem, setDrunkardForRedeem] = useState({});

    const filteredList = drunkardsSelected.filter(item =>
            item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.firstName + " " + item.lastName).toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.drunkardName.toLowerCase().includes(searchTerm.toLowerCase())
        );

    function handleItemClick(userProfile){
        const tmp_drunkardsSelected = drunkardsSelected.map(item => {
            if (item.userId === userProfile.userId){
                return {...item, selected: !item.selected};
            } else {
                return {...item}
            }
        })
        setDrunkardsSelected(tmp_drunkardsSelected)
    }

    function handleClearSelect(){
        setDrunkardsSelected(drunkards.map(item => ({...item, selected: false})));
    }


    function handleRedeemOpen(userId){
        const this_d = drunkards.find(item => item.userId === userId);
        setDrunkardForRedeem(this_d)
        onRedeemOpen();
    }

    function handleBSOpen(userId, viaSingleButton = false){
        if (viaSingleButton){
            console.log("opened via single button")
            const this_d = drunkards.find(item => item.userId === userId);
            setDrunkardsForModal([this_d])
            console.log("BS for", this_d)
        } else {
            setDrunkardsForModal(drunkardsSelected.filter(item => item.selected))
        }
        onBSOpen();
    }

    const maxph="100%"
    const maxh="100%"

    return (<>
        <Box h="100%" display="flex" flexDirection="column">
        {/* ToDo: Is this modal required here or can we use the modal in the parent? Can we, if we combine the lists, move the parent modal here / kill it in the parent? */}
        <BierstrichModal
            isOpen={isBSOpen}
            onClose={onBSClose}
            drunkards={drunkardsForModal}
            handleBS={handleBSConfirm}
        />
        <RedeemModal
                isOpen={isRedeemOpen}
                onClose={onRedeemClose}
                drunkard={drunkardForRedeem}
                handleRedeem={handleRedeemConfirm}
            />
        <Box flex={1} h={maxph} overflow="hidden">
        <VStack spacing={0} alignItems="justify">
                <InputGroup>
                    <Input
                        placeholder="Liste durchsuchen"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputRightElement>
                        {searchTerm && (
                        <IconButton
                            aria-label="Clear Search"
                            icon={<IoCloseOutline />}
                            onClick={clearSearch}
                            bg="white"
                            size="90%"
                            mt={1} mb={1} mr={1}
                        />
                        )}
                    </InputRightElement>
                </InputGroup>
            </VStack>
            {drunkardsSelected.filter(item => item.selected).length > 0 ?
            <>
                <Grid templateColumns="1fr 3fr 1fr" alignItems="center" mt={1} mb={1}>
                    <GridItem>
                        <Center>
                        <BSButton
                            style={{ borderRadius: '0' }}
                            onClick={() => handleClearSelect()}
                            w="full"
                        >
                            <IoCloseOutline size={20} />
                        </BSButton>
                        </Center>
                    </GridItem>

                    <GridItem>
                        <Center><Text fontSize="18px" mb={0}>Sippenhaft</Text></Center>
                    </GridItem>

                    <GridItem>
                        <Center>
                        <BSButton
                            style={{ borderRadius: '0' }}
                            onClick={() => handleBSOpen("None", false)}
                            w="full"
                        >
                            <IoBeerOutline size="20px" /><Text size="20px">+</Text>
                        </BSButton>
                        </Center>
                    </GridItem>
                </Grid>
            </>:<></>}
            <VStack spacing={0} alignItems="stretch" overflowY="auto" maxH={maxh}>
                {filteredList.map((item, iter) => (
                    <div key={item.userId}>
                        <DrunkardDataWButtons
                            userProfile={item}
                            handleAdmin={handleAdmin}
                            isAdminLoading={isAdminsLoading.hasOwnProperty(item.userId) && isAdminsLoading[item.userId]}
                            handleExclude={handleExclude}
                            isExcludeLoading={isExcludeLoading.hasOwnProperty(item.userId) && isExcludeLoading[item.userId]}
                            handleRedeem={handleRedeemOpen}
                            isRedeemLoading={isRedeemLoading.hasOwnProperty(item.userId) && isRedeemLoading[item.userId]}
                            handleBS={handleBSOpen}
                            isBSLoading={isBSLoading.hasOwnProperty(item.userId) && isBSLoading[item.userId]}
                            handleSelectClick={handleItemClick}
                            buttonsDisabled={drunkardsSelected.filter(item => item.selected).length > 0 ? true : false}
                        />
                    </div>
                ))}
            </VStack>
        </Box>
    </Box></>);
    }


export function ApplicantDataWButtons({userProfile, handleAccept, isAcceptLoading, handleEliminate, isEliminateLoading}){
    const userId = userProfile["userId"];
    return <>
        <DrunkardData userProfile={userProfile} showStats={false}/>
        <Flex textStyle="cardText" alignItems='flex-end' justify="space-between" width="full" px={0} mb={0} mt={2} ml={0} mr={0}>
            <DButton
                onClick={() => handleAccept(userId)} 
                borderRadius={0} 
                ml='auto' p={0}
                isLoading={isAcceptLoading}
            >
                <VStack spacing={0}><Text fontSize="xs" fontWeight="normal" mb={0}>Zulassen</Text><IoPersonAddOutline size="20px" /></VStack>
                </DButton>
            <DButton 
                onClick={() => handleEliminate(userId)} 
                borderRadius={0} 
                ml='auto' p={0}
                isLoading={isEliminateLoading}
            >
                <VStack spacing={0}><Text fontSize="xs" fontWeight="normal" mb={0}>Ablehnen</Text><IoBanOutline size="20px" /></VStack>
            </DButton>
        </Flex>
    </>
}



export function ApplicantList({drunkards, handleAccept, isAcceptLoading, handleEliminate, isEliminateLoading}) {
    return (
        <VStack spacing={0} alignItems="justify">
            {drunkards.map((item, iter) => (
                <div key={item.userId}>
                    <ApplicantDataWButtons
                        userProfile={item}
                        isAcceptLoading={isAcceptLoading.hasOwnProperty(item.userId) && isAcceptLoading[item.userId]}
                        handleAccept={handleAccept}
                        isEliminateLoading={isEliminateLoading.hasOwnProperty(item.userId) && isEliminateLoading[item.userId]}
                        handleEliminate={handleEliminate}
                    />
                </div>
            ))}
        </VStack>)
    ;
}