import React from "react";
import "./Buttons.css";
//import {Button} from "antd";

import {  Button,} from "@chakra-ui/react";
/*import {    BsPlusLg } from "react-icons/bs"
import {RiEdit2Line, RiDeleteBin6Line} from "react-icons/ri"
import {FiMoreVertical} from "react-icons/fi"
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai"
import {MdAttachFile, MdOutlineCancel} from "react-icons/md"*/
import {FaArrowRight, FaArrowLeft} from "react-icons/fa"

export function AdminMenuButton({
  className="BSButton",
  isLoading = false,
  disabled = false,
  ... props
}) {
return (
  <BSButton
    flex={1} 
    borderRadius={0} 
    h={65}
    borderWidth="thin" 
    borderColor="#4A5568"
    p={0}
    {...props}
  >
    {props.children}
  </BSButton>

);
}

export function DButton({
...props
}){
  return(
  <BSButton
    flex={1} 
    borderRadius={0} 
    mb={0}
    bg="#EDF2F7"
    borderWidth="thin" 
    {...props}
  >
    {props.children}
  </BSButton>
  );
}


export function BSButton({
  className="BSButton",
  isLoading = false,
  disabled = false,
  ...props
}) {
  return (
    <Button
      color="black" 
      bg="yellow.300" 
      colorScheme="yellow" 
      isDisabled={disabled || isLoading}
      isLoading={isLoading}
      className={className}
      
      {...props}
      
    >
      {props.children}
    </Button>
  );
}

export function NextButton({
    className= "BSButton",
    ...props
}) {
    return(
      <Button
       className={className}

       {...props}
      >
      <FaArrowRight size={22}/>
      </Button>
    );
}

export function PreviousButton({
    className= "BSButton",
    ...props
}) {
    return(
      <Button
      className={className}
      {...props}
      >
      <FaArrowLeft size={22}/>
      </Button>
    );
}