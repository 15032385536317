import { API } from "aws-amplify";
import { onError } from "./errorLib";

export function assert(condition, message) {
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }
}

export function getIsAdmin(eventId){
    return API.get("bierstriche", `/eventregistrations_is_admin/${eventId}`)
}

export async function putDrunkardAdmin(content_dict, eventId){
    const response = await API.put("bierstriche", `/eventregistrations/${eventId}/admin`, {body: content_dict})
    return {status: response.ResponseMetadata.HTTPStatusCode}
}

async function acceptDrunkardToEvent(content_dict, eventId){
    const response = await API.put("bierstriche", `/eventregistrations/${eventId}/accept`, {body: content_dict})
    return {status: response.ResponseMetadata.HTTPStatusCode}
}

async function redeemForDrunkardEvent(content_dict, eventId){
    const response = await API.put("bierstriche", `/eventregistrations/${eventId}/redeem`, {body: content_dict})
    return {status: response.ResponseMetadata.HTTPStatusCode}
}

async function updateDrunkardInEvent(content_dict, eventId, drunkardId){
    const response = await API.put("bierstriche", `/events/${eventId}/${drunkardId}`, {body: content_dict})
    return {status: response.ResponseMetadata.HTTPStatusCode}
}

export async function updateRedeemed(eventId, userId, rdcount){
    const updateDict={
        "content": {
            "userId": userId,
            "redeemed": rdcount
            }
    };  
    const response = await redeemForDrunkardEvent(updateDict, eventId)
    return response;
}

export async function acceptDrunkard(eventId, userId, drunkardName, accept=true){
    const updateDict = {"redeemed": 0, "bierstrichCount": 0, "drunkardName": drunkardName}; // redeemed and bierstrichCount not required anymore, but adding the drunkard to the dict incl. the name is still needed
    const acceptDict={
        "content": {
            "userId": userId,
            "accept": accept
        }
    }  
    //ToDo: Single Endpoint!
    const a1 = await acceptDrunkardToEvent(acceptDict, eventId);
    if (a1.status !== 200){
        return a1;
    }
    console.log("in accept loading lib", accept)
    if (accept === true){
        const a2 = await updateDrunkardInEvent({"content": updateDict}, eventId, userId);
        console.log("in accept loading lib, called backend", accept)
        return a2;
    }
    return a1;
}



export async function eventPut(eventId, updateEventDict){
        
    let response;
    
    try {
        if (eventId === "new"){
            response = await API.post("bierstriche", "/events", {body: {"content": updateEventDict}})
        } else {
            response = await API.post("bierstriche", `/events/${eventId}`, {body: {"content": updateEventDict}})
        }
        return {status: response.ResponseMetadata.HTTPStatusCode,
            eventData: response.putData,
        }
    } catch (error) {
        return {status: 500}
    } 
}

export async function postBierstrich(contentDict){
    const response = await API.post("bierstriche", "/bierstriche", {body: contentDict})
    console.log(response);
    return {status: response.ResponseMetadata.HTTPStatusCode,
        bsData: response.putData,
    }
}


export async function createEventregistration(eventId, userId){
    const initRegistration = {
        "content": {
            "userId": userId,
            "isAdmin": false,
            "isOwner": false,
            "acceptStatus": 0
            }
    }
    const response = await API.post("bierstriche", `/eventregistrations/${eventId}`, {body: initRegistration})
    console.log(response);
    return {status: response.ResponseMetadata.HTTPStatusCode}
}


export function loadProfile(){
    function loadProfileInfo() {
        return API.get("bierstriche", "/drunkards/generic", {});
    }
    async function onLoadProfile() {
      try {
        const loaded_dict = await loadProfileInfo();
        if ("status" in loaded_dict) {
            if (loaded_dict["status"] === "notfound") {
                return {};
            }
        }
        return loaded_dict;
      } catch (e) {
        onError(e);
      }
    }
    return onLoadProfile();
}

export function loadBierstriche(){
    function loadBierstrichInfo(){
        return API.get("bierstriche", "/bierstriche/drunkard")
    }
    async function onLoadBierstriche(){
        try {
            const loadedBierstriche = await loadBierstrichInfo();
            if ("status" in loadedBierstriche){
                if (loadedBierstriche["status"] === "notfound"){
                    return []
                }
            }
            return loadedBierstriche;
        } catch (e){
            onError(e);
            return [];
        }
    }
    return onLoadBierstriche();
}

export function loadEvents(){
    function loadEventsInfo(){
        return API.get("bierstriche", "/events/all")
    }
    async function onLoadEvents(){
        try {
            const loadedData = await loadEventsInfo();
            if ("status" in loadedData){
                if (loadedData["status"] === "notfound"){
                    return []
                }
            }
            return loadedData;
        } catch (e){
            onError(e);
            return [];
        }
    }
    return onLoadEvents();
}

export function loadEvent(eventId){
    function loadEventInfo(){
        return API.get("bierstriche", `/events/${eventId}`)
    }
    async function onLoadEvent(){
        try {
            const loadedData = await loadEventInfo();
            if ("status" in loadedData){
                if (loadedData["status"] === "notfound"){
                    return {}
                }
            }
            return loadedData;
        } catch (e){
            onError(e);
        }
    } 
    return onLoadEvent();
}

export function updateEventInEvents(events, eventId, eventToUpdate){
    console.log("updating event in Events");
    console.log(eventToUpdate);
    console.log(eventId);
    const updated_events = {
        ...events,
        [eventId]: eventToUpdate
      }
      return updated_events;
}

async function loadFromStorage(localidentifier, apiCall){
    async function loadAndResetStorage(){
        console.log("no "+localidentifier+" in local storage, loading from API");
        const APIData = await apiCall;
        localStorage.setItem(localidentifier, JSON.stringify(APIData));
        console.log(localidentifier+" from API stored in local storage")
        return APIData;
    }
    try {
        const storedData = localStorage.getItem(localidentifier);
        if (storedData) {
            console.log("using "+localidentifier+" from local storage");
            console.log(JSON.parse(storedData));
            return JSON.parse(storedData);
        } else {
            return loadAndResetStorage();
        }
    } catch (error) {
        return loadAndResetStorage();
    }
}


//ToDo: make a single event loader that automatically enters kings. This is in Events, Card, and below implemented separately
export async function loadDataSingleEvent(eventId, userId, isAdmin=false){
    async function loadSingleEvent(eventId){
        function loadEventInfo(){
            return API.get("bierstriche", `/events/compileddata/${eventId}`)
        }
        async function onLoadEvent(){
            try {
                const loadedData = await loadEventInfo();
                if ("status" in loadedData){
                    if (loadedData["status"] === "notfound"){
                        return {}
                    }
                }
                return loadedData;
            } catch (e){
                onError(e);
            }
        }
        const loadedEvent = await onLoadEvent();
        return loadedEvent;
    }


    const [event_dict, bierstrich_list, bierstrich_list_all, event_drunkards] = await loadSingleEvent(eventId)
    event_dict["ownEventCount"] =  getTotalForEventUser(bierstrich_list, eventId, userId);

    if (isAdmin ){
        event_drunkards.forEach(item => {
            item["ownEventCount"] = getTotalForEventUser(bierstrich_list_all, eventId, item.userId);
        })
    }
    return {loadedEvent: event_dict,
        loadedBierstriche: bierstrich_list,
        loadedBierstricheAll: bierstrich_list_all,
        loadedDrunkards: event_drunkards,
    }
}


export function getTotalForEventUser(bierstrichList, eventId, userId){
    // function to update Bierstrichcount for a specific user for a specific event. 
    const userEventBierstrichList = bierstrichList.filter(item => item.eventId ===  eventId).filter(item => item.userId === userId);
    const totalCount = userEventBierstrichList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0);
    return totalCount;
}


export async function loadDataEvents(){
  var bierstrich_list = [];
  var events_list = {};
  //ToDo: Combine into Single API call;
  bierstrich_list = await loadBierstriche();
  events_list = await loadEvents();
  return( [bierstrich_list, events_list] );
}

export async function loadDataMain(){
  const tmp_profile = await loadFromStorage("profile", loadProfile());
  const bierstrich_list = await loadFromStorage("bierstriche", loadBierstriche());
  const events_dict = await loadFromStorage("events", loadEvents());
  for (const [, subObject] of Object.entries(events_dict)){
    if (subObject.accepted){
        subObject["ownEventCount"] = getTotalForEventUser(bierstrich_list, subObject.eventId, tmp_profile.userId);
    }
  }


  let  profile_dict= {...tmp_profile};
  profile_dict["totalCount"] = getBierstrichSum(bierstrich_list);
  return {loaded_profile: profile_dict, loaded_bierstriche: bierstrich_list, loaded_events: events_dict};
}

export function validateProfile(in_profile, validation_scheme){
    var is_valid = true;
    var message = "";
    const mykeys = Object.keys(validation_scheme);
    for (let i=0; i< mykeys.length; i++){
        const this_key = mykeys[i];
        if (validation_scheme[this_key].required){
            is_valid = (this_key in in_profile); 
            if (!is_valid) {
                message = this_key + " ist ein Pflichtfeld!";
                break;
            }
        }
        if ("min" in validation_scheme[this_key] && is_valid ){
            is_valid = in_profile[this_key].length >= validation_scheme[this_key].min;
            if (!is_valid) {
                message = this_key+" muss mindestens "+validation_scheme[this_key].min+ " Zeichen lang sein.";
                break;
            }
        }
    }
    return [is_valid, message];
}

export function getBierstrichSum(this_list){
    var totalCount = 0;
    this_list.forEach(item => {
        totalCount += item["count"]
    })
    return totalCount;
}

