import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Main from "./containers/DrunkardMain";
import ResetPassword from "./containers/ResetPassword"
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "./lib/contextLib";



//import About from "./containers/About";
import DrunkardProfile from "./containers/DrunkardProfile";
import Events from "./containers/Events"
import EventCreateEdit from "./containers/EventCreateEdit"

import EventView from "./containers/EventView";
/*import EventParticipants from "./containers/EventParticipants"
import Bierstrich from "./containers/Bierstrich"
*/




export default function Links() {
  const {isAuthenticated} = useAppContext();
  function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
  }

  return (
    <Routes>
      <Route path="/" element={<RequireAuth><Main /></RequireAuth>} /> 
      <Route path="/profile" element={<RequireAuth><DrunkardProfile /></RequireAuth>} /> 
      <Route path="/login" element={<Login />} /> 
      <Route path="/login/reset" element={<ResetPassword />} /> 
      <Route path="/signup" element={<Signup />} /> 
      <Route path="/events" element={<RequireAuth><Events /></RequireAuth>} /> 
      <Route path="/events/create/:eventId" element={<RequireAuth><EventCreateEdit /></RequireAuth>} /> 
      <Route path="/events/:eventId" element={<RequireAuth><EventView /></RequireAuth>} /> 
      <Route path="*" element={<NotFound />}/>
    </Routes>
  );
}
