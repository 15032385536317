import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: `'Open Sans', sans-serif`,
    heading:  `'Open Sans', sans-serif`,
    // Add more font styles as needed
  },
  textStyles: {
    cardText: {
      fontSize: 'sm', // Set your desired fontSize
      fontWeight: "normal"
    },
    bierstrichText: {
      fontSize: 'sm',
    },
    iconAddonText: {
      fontSize: 'xl'
    }
  },
});

export default theme;