import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Center,
} from '@chakra-ui/react'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Box
} from "@chakra-ui/react";

import {BSButton as Button, DButton} from "../components/Buttons"
import { DateTimePicker } from "react-rainbow-components";

import {  useFormik } from 'formik';
import * as Yup from 'yup';

export default RedeemModal;

function RedeemModal({isOpen, onClose, drunkard, handleRedeem=() => {}, ...props}){
  const [count, setCount] = useState(props.initCount ? props.initCount : 0);
  const handleInputChange = (event) => {
    const value = event.target.value;

    // Validate that the entered value is a valid integer
    if (/^\d*$/.test(value)) {
      // Update the state with the parsed integer value
      setCount(value === "" ? "" : parseInt(value, 10));
    } else {
      // Handle invalid input (non-integer)
      // For example, you can display an error message or prevent updating the state
      console.log("Invalid input. Please enter a valid integer.");
    }
  };
  
  return (
    <>
      {/*<Button onClick={onOpen}>Open Modal</Button>*/}

      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        borderRadius={0}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0} p={0} textStyle="cardText">
          <ModalHeader><Text>Bierstriche einlösen</Text></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Center><Text>{drunkard ? drunkard.drunkardName : ""}</Text></Center>
            <Flex textStyle="cardText" alignItems='flex-end' justify="space-between" width="full" px={0} mb={0} mt={2} ml={0} mr={0}>
              <DButton
                  isLoading={false}
                  onClick={() => {setCount(count > 0 ? count-1 : 0);}}
              >-</DButton>
              <Center flex={4}>
                <Input
                  value={count}
                  textAlign="center"
                  onChange={handleInputChange}
                />

              </Center>
              <DButton
                  onClick={() => {setCount(count+1);}}
                  isLoading={false}
              >+</DButton>
            </Flex>
          </ModalBody>

          <ModalFooter pt={4} pl={0} pr={0} pb={4}>
            <Button 
              width="full" 
              onClick={() => handleRedeem(drunkard, count)}
            >Einlösen</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


export function BierstrichFormComponent({handleSubmitBS, isLoading=false, drunkards = []}){
  const formik = useFormik({
    initialValues: {
      bsCount: 1,
      bsReason: "",
      bsDate: new Date()
    },
    onSubmit: async (values) => {
      await handleSubmitBS(values, drunkards);
    },
    validationSchema: Yup.object({
        bsCount: Yup.number().integer("needs to be integer").positive("Muss größer 0 sein").required("Required"),
        bsReason: Yup.string().required("Required").min(5),
        bsDate: Yup.date().required("Gib ein Datum an")
    }),
  });

  return(
    <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
      <Box>
      <FormControl isInvalid={formik.touched.bsCount && formik.errors.bsCount}>
        <Flex textStyle="cardText" alignItems='flex-end' justify="space-between" width="full" px={0} mb={0} mt={2} ml={0} mr={0}>
          <DButton
              isLoading={false}
              onClick={() => {formik.setFieldValue('bsCount', formik.values.bsCount > 0 ? formik.values.bsCount -1 : 0); formik.validateField('bsCount');}}
              //() => {setCount(count > 0 ? count-1 : 0);}
          >-</DButton>
          <Center flex={4}>
          <Input
            name="bsCount"
            id="bsCount"
            value={formik.values.bsCount}
            textAlign="center"
            onChange={(event) => {
              const newValue = parseInt(event.target.value, 10) || 0; // Parse as integer or default to 0
              formik.setFieldValue('bsCount', newValue);
              formik.validateField('bsCount');
            }}
            onBlur={formik.handleBlur}
          />
          </Center>  
          <DButton
              onClick={() => {formik.setFieldValue('bsCount', formik.values.bsCount +1 ); formik.validateField('bsCount');}}
              isLoading={false}
          >+</DButton>
        </Flex>
        <FormErrorMessage>{formik.errors.bsReason}</FormErrorMessage>
      </FormControl>
        <FormControl isInvalid={formik.touched.bsReason && formik.errors.bsReason} px={4}>
            <FormLabel htmlFor="bsReason">Grund:</FormLabel>
            <Input
                id="bsReason"
                name="bsReason"
                value={formik.values.bsReason}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <FormErrorMessage>{formik.errors.bsReason}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formik.touched.bsDate && formik.errors.bsDate} px={4}>
          <DateTimePicker
            id="bsDate"
            name="bsDate"
            onChange={date => {formik.setFieldValue('bsDate', date); formik.validateField('bsDate');}}
            onBlur={date => {formik.validateField('bsDate')}}
            showYearDropdown
            dateFormat="MM/dd/yyyy"
            popperPlacement="bottom-start"
            value={formik.values.bsDate}
            locale="de-DE"
            label="Datum / Zeit"
            hour24
          />  
          <FormErrorMessage>{formik.errors.bsDate}</FormErrorMessage>
        </FormControl>
        <Center>
        <Button m={4} mb={0} type="submit" width="100%" isLoading={isLoading} style={{fontWeight: "bold"}} >
          {formik.dirty ? "Speichern" : "Zurück" }
        </Button>
        </Center>
        </Box>
      </form>
  );
}


export function BierstrichModal({isOpen, onClose, handleBS=() => {}, drunkards = [], ...props}){
  return (
    <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        borderRadius={0}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0} p={0} textStyle="cardText">
          <ModalHeader><Text>Bierstrich</Text></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Center style={{ flexWrap: 'wrap' }} textAlign="center"><Text>{" - "}  
            {drunkards.map((item) => (
                <React.Fragment key={item.userId}> 
                   {item.drunkardName + " - "} 
                </React.Fragment>
            ))}
            </Text></Center>
            <BierstrichFormComponent
              handleSubmitBS={handleBS}
              drunkards={drunkards}
            />
          </ModalBody>
          <ModalFooter pt={4} pl={0} pr={0} pb={4}>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );


} 

export function PopNotice(props){
    return(
    <Modal
        size="md"
        isOpen={props.show}
        onClose={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader closeButton>
          
            {props.title}
          
        </ModalHeader>
        <ModalBody>{props.text}</ModalBody>
        <ModalFooter>

                    <Button
                        onClick={() => {
                            props.handleConfirm ? props.handleConfirm() : props.setShow(false)
                        }}
                    >{props.confirmText ? props.confirmText : "OK"}</Button>

         </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


/*export function PopConfirmChildren(props){
    return(
    <Modal
        size="md"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
            <Container fluid>
                <Row>
                <Col className="text-center">
                    <Button
                        onClick={() => props.setShow(false)}
                    />
                </Col>
                <Col className="text-center">
                    <Button
                        variant="secondary"
                        onClick={props.handleConfirm}
                    > {props.confirmText ? props.confirmText : "Confirm"}
                    </Button>

                </Col>

                </Row>
            </Container>
         </Modal.Footer>
      </Modal>

    )
}*/
