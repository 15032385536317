import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  Form
} from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./ResetPassword.css";
import PopNotice from "../components/Popups"

import {BSButton} from "../components/Buttons";


export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [showConfirmationCodeSent, setShowConfirmationCodeSent] = useState(false);
  const [validated, setValidated] = useState(false);

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();
    setIsSendingCode(true);
    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
      setShowConfirmationCodeSent(true)
    } catch (error) {
      onError(error);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false){
        event.stopPropagation();
        setValidated(true);
    } else{

        setIsConfirming(true);
        try {
          await Auth.forgotPasswordSubmit(
            fields.email,
            fields.code,
            fields.password
          );
          setValidated(false);
          setConfirmed(true);
        } catch (error) {
          onError(error);
          setIsConfirming(false);
        }
    }
  }

  function renderRequestCodeForm() {
    return (
      <Form onSubmit={handleSendCodeClick}>
        <Form.Group bsSize="large" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
          <Form.Text muted>A confirmation code to reset your password will be sent to this email address</Form.Text>
        </Form.Group>
        
        <BSButton 
          width="full" 
          style={{fontWeight: "bold"}} 
          isLoading={isSendingCode} 
          type="submit"
          disabled={!validateCodeForm()}
        >
          Send Confirmation Code
        </BSButton>
      </Form>
    );
  }

  function renderConfirmationForm() {
    return (
      <Form noValidate validated={validated} onSubmit={handleConfirmClick}>
        <Form.Group bsSize="large" controlId="code">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            pattern="^[0-9]+$"
            value={fields.code}
            onChange={handleFieldChange}
          />
          <Form.Control.Feedback type='invalid'>
             Code pattern is invalid: only digits and no spaces!
          </Form.Control.Feedback>
          <Form.Text>
            Please check your email ({fields.email}) for the confirmation code.
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group bsSize="large" controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?/()§=])([a-zA-Z0-9-+_!@#$%^&*.,?/()§=]+){8,}$"
            value={fields.password}
            onChange={handleFieldChange}
          />
          <Form.Control.Feedback type='invalid'>
             Incorrect password pattern: 8 characters, must contain at least 1 capital and 1 small case letter, a digit & 1 special character (-+_!@#$%^&*.,?/()§=). No "ä, ö, ü" ;)
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group bsSize="large" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?/()§=])([a-zA-Z0-9-+_!@#$%^&*.,?/()§=]+){8,}$"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
          <Form.Control.Feedback type='invalid'>
             Incorrect password pattern: 8 characters, must contain at least 1 capital and 1 small case letter, a digit & 1 special character (-+_!@#$%^&*.,?/()§=). No "ä, ö, ü" ;)
          </Form.Control.Feedback>
        </Form.Group>

        <BSButton 
          width="full" 
          style={{fontWeight: "bold"}} 
          isLoading={isConfirming} 
          type="submit"
          disabled={!validateResetForm()}
        >
          Confirm
        </BSButton>
      </Form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p><BsCheck size={16} /> Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
      <PopNotice
                show={showConfirmationCodeSent}
                setShow={setShowConfirmationCodeSent}
                text={`A confirmation code to reset your password was sent to ${fields.email}`}
                title="Confirmation Code for PW Reset"
                handleConfirm={() => setShowConfirmationCodeSent(false)}
                confirmText="Ok"
        />
        <p className="text-center pt-3">Reset Password</p>
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  );
}
