import React, { useEffect } from "react";
import { ListItem, List, ListIcon } from '@chakra-ui/react';

import {Heading, Text, Flex} from "@chakra-ui/react"
import "./DrunkardMain.css"
import { LinkerCardChakra } from "../components/Cards";
import { useNavigate } from 'react-router-dom'

import { useProfileContext, useAlertContext } from "../lib/contextLib";

import { validateProfile } from "../lib/loadingLib";

import { validation_scheme } from "./DrunkardProfile";

import { IoBeerOutline } from "react-icons/io5";


export default function Main() {
    
    //const { setIsFullyLoading, setLoadingText} = useGlobalLoaderContext();

    const { profile } = useProfileContext();

    const navigate = useNavigate();

    const {onOpen} = useAlertContext();

    useEffect(() => {
        const loadAndAwait = async () => {
            // initial check if there is a profile. If not (first visit), redirect to profile page          
            if (profile.hasOwnProperty["not_set"]){ // <2 in case there are things like totalCount added upon load for old data.
                navigate("/profile")  
            } else {
                // if there is a profile, we check it to make sure that all data is there
                  if (Object.keys(profile).length!==0){ // only do this, if profile is not empty and profile is not {not_set: true}. Somehow, at the very beginning of load, profile is set to {} and I do not know yet why. If loaded profile is empty, profile is set to {not_set: true} in App.js useEffect()
                    const [is_valid_profile, validation_message] = validateProfile(profile, validation_scheme);
                    // if valid, set the Profile and continue. If not, redirect to profile page with an Alert
                    if (!is_valid_profile){
                        onOpen("failed", "Dein Profil ist unvollständig: "+validation_message);
                        navigate("/profile");      
                    }
                }
            }
        }
        loadAndAwait();
    }, [navigate, onOpen, profile]);



  return(
    <div>
        
        <div className="Main">
            <div className="landing">
                <Heading as="h1" size='lg'>Willkommen bei Bierstriche.de</Heading>
                <Text fontSize="16px">Digitale Bierstrichlisten für Veranstaltungen, Gruppenausflüge, Vereine, WGs, oder wo immer ihr eine einfache Möglichkeit braucht, Regelverstöße zum Wohlsein Aller zu ahnden!</Text>
            </div>
            <div className="links">
                <div>
                    <LinkerCardChakra 
                        linkto="/profile"
                        title="Dein Profil"
                    >

                        <List spacing={3}>
                        <ListItem listStyleType="none">
                            <Flex alignItems="center">
                            <ListIcon as={IoBeerOutline} boxSize={5} />
                            <Text style={{marginBottom: '0px'}} display="inline-block">Erstelle dein Profil, um an Events teilzunehmen.</Text>
                            </Flex>
                        </ListItem>
                        <ListItem listStyleType="none">
                            <Flex alignItems="center">
                            <ListIcon as={IoBeerOutline} boxSize={5}/>
                            <Text style={{marginBottom: '0px'}} display="inline-block">Die hinterlegten Daten können von Event-Admin und Teilnehmern eingesehen werden.</Text>
                            </Flex>
                        </ListItem>
                        </List>
                        
                        
                    </LinkerCardChakra>
                </div>
                <div className="pt-3">
                    <LinkerCardChakra
                        linkto="/events"
                        title="Deine Events"
                        className="pt-3"
                    >
                        <List spacing={3}>
                        <ListItem listStyleType="none">
                            <Flex alignItems="center">
                            <ListIcon as={IoBeerOutline} boxSize={5} />
                            <Text style={{marginBottom: '0px'}} display="inline-block">Nimm an Events teil, um deine Bierstriche in Echtzeit im Überblick zu haben.</Text>
                            </Flex>
                        </ListItem>
                        <ListItem listStyleType="none">
                            <Flex alignItems="center">
                            <ListIcon as={IoBeerOutline} boxSize={5}/>
                            <Text style={{marginBottom: '0px'}} display="inline-block">Erstelle eigene Events & lade die Teilnehmer per Link dazu ein. Verteile Bierstriche über die App und dokumentiere die Einlösung.</Text>
                            </Flex>
                        </ListItem>
                        </List>
                    </LinkerCardChakra>
                </div>
            </div>
        </div>
        
    </div>
    )
}