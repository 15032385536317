import React, { useState , useEffect} from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
import "./components/Header.css"
import Routes from "./Routes";

import { AlertProvider, AppContext, ProfileContext, EventProvider, GlobalLoaderProvider, useGlobalLoaderContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./lib/errorLib";
import { loadDataMain } from "./lib/loadingLib";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/Header"
import LoadingOverlay from "react-loading-overlay";
import Alert from "./components/Alert"

import theme from "./theme";

const Loader = ({force_active, force_text, children}) => {
  const {isFullyLoading, loadingText} = useGlobalLoaderContext();
  return (<LoadingOverlay
  className="LoadingFullOverlay"
    active={isFullyLoading || force_active}
    spinner
    text={loadingText ? loadingText : force_text}
    styles={{ overlay: (base) => ({ ...base, zIndex: 2000 }) }}
  >
    {children}
  </LoadingOverlay>
  )
}

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [initLoading, setInitLoading] = useState(true); // apparently I cannot load the useGlobalLoaderContext hook directly
  const [profile, setProfile] = useState({});
  const [bierstriche, setBierstriche] = useState([]);
  const [events, setEvents] = useState({});
  /*function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }*/






  useEffect(() => {
    async function onLoadInitData(){
      const {loaded_profile, loaded_bierstriche, loaded_events} = await loadDataMain();
      if (Object.keys(loaded_profile).length===0){
        setProfile({not_set: true})
      } else {
        setProfile(loaded_profile);
        setEvents(loaded_events);
        setBierstriche(loaded_bierstriche);
      }
      return {loaded_profile, loaded_bierstriche, loaded_events};
  }

    async function onLoad() {
      setInitLoading(true);
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
        await onLoadInitData();
      }
      catch(e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }
      setIsAuthenticating(false);
      setInitLoading(false);
    }

    const loadAndAwait = async () => {
      //console.log("refreshing in App.js")
      await onLoad();
      //console.log(isAuthenticated);
      //console.log(localStorage.getItem('profile'))
      /*setBierstriche(loaded_bierstriche);
      setEvents(loaded_events);*/
      if (isAuthenticated){
        console.log("authenticated: ")
        console.log(isAuthenticated);
      }
    };
    loadAndAwait();
  }, [isAuthenticated]);


  return (
    !isAuthenticating && (

    <div className="App">
      <ChakraProvider theme={theme}>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <AlertProvider>
      <GlobalLoaderProvider> 
        <ProfileContext.Provider value={{profile, setProfile, bierstriche, setBierstriche, events, setEvents}}>
          <EventProvider>
            <Loader
              force_active={initLoading}
              force_text={"Initialisierung"}
            >
              <Header/>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
                <Alert/>
            </Loader>
          </EventProvider>
        </ProfileContext.Provider>
        </GlobalLoaderProvider>
        </AlertProvider>
      </AppContext.Provider>
      </ChakraProvider>
    </div>
    )
  );
}

export default App;
