import "./Header.css"

import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { useAppContext, useGlobalLoaderContext, useProfileContext } from "../lib/contextLib";
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Heading, VStack, Text, Center, Flex, Menu, MenuButton, MenuList, MenuItem, Spacer, IconButton } from "@chakra-ui/react"

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link } from '@chakra-ui/react'

import { IoBeerOutline, IoCloseOutline, IoSettingsOutline, IoReorderThreeOutline } from "react-icons/io5";

//import { GiHamburgerMenu } from "react-icons/gi";

export function SubHeader({title, headheight="60px",...props}){
  const navigate = useNavigate();
  return (
    <Flex bg='yellow.300' h={headheight} pl='20px' pr='20px'>
      <Center w='60px'>
        <Button
          mr='auto'
          as={IconButton} 
          icon={<IoCloseOutline size="30px"/>}
          fontSize='1.em'
          size='xl'
          onClick={()=>navigate(-1)}
          bg="yellow.300"
        />
      
      </Center>
      <Spacer/>
      <Center w='200px'>
        <Flex>
          <Heading size='lg' mb="2px" mt='2px'>{title}</Heading>
        </Flex>
      </Center>
      <Spacer/>
      <Center w='60px'>
      </Center>
    </Flex>
  )
}

export function EventViewHeader({event, preCloseClick=() => {}, headheight="60px",...props}){
  const navigate = useNavigate();
  const eventId = event.eventId;
  return (
    <GenericHeader headerheight={headheight} hideOnScroll={props.hideOnScroll}>
    <Flex bg='yellow.300' h={headheight} pl='20px' pr='20px'>
      <Center w='60px'>
        <Button
            mr='auto'
            as={IconButton} 
            icon={<IoCloseOutline size="30px"/>}
            fontSize='1.em'
            size='xl'
            onClick={()=>{preCloseClick(); navigate(-1)}}
            bg="yellow.300"
          />
      </Center>
      <Spacer/>
      <Center w='200px'>
        <Flex>
          <Heading size='md' mb="2px" mt='2px' isTruncated>{event.eventName}</Heading>
        </Flex>
      </Center>
      <Spacer/>
      <Center w='60px'>
        {event.isAdmin ? 
        <Button
            ml='auto'
            as={IconButton} 
            icon={<IoSettingsOutline size={24}/>}
            fontSize='3.em'
            size='2xl'
            onClick={() => {
              navigate('/events/create/'+eventId)
            }}
            bg="yellow.300"
        />
        :<></>}
      </Center>
    </Flex>
    </GenericHeader>
  )
}

const GenericHeader = ({headerheight, ...props}) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const prevScrollY = useRef(0);
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position to determine if scrolling down or up
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setIsScrollingDown(prevScrollY.current < scrollY );
      prevScrollY.current = scrollY;     
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return(
  <>
  <Box
    className="header"
    position="fixed"
    top={0}
    left={0}
    right={0}
    transform={props.hideOnScroll ? (isScrollingDown ? "translateY(-100px)" : "translateY(0)"): "none"}
    transitionProperty="transform"
    transitionDuration="0.3s"
    transitionTimingFunction="ease-in-out"
  >
    {props.children}
  </Box>
  <Box h={headerheight}/>
  </>
  )
}

export default function Header() {

  let headerheight="60px";

  
  const [menu, setMenu] = useState("None");
  const location = useLocation();
  const {isFullyLoading} = useGlobalLoaderContext();
  

  // ToDo: Check if we can set renderedMenu as state directly in useEffect, instead of doing the switch below

  useEffect(()=> {
      const correct_path = (location.pathname === '/' || location.pathname === "/login" || location.pathname === "/signup");
      if (!isFullyLoading && correct_path) {
        setMenu("Main");
      } else if (location.pathname === '/events') {
        setMenu("Events");
      } else if (location.pathname === '/profile'){
        setMenu("Profile");
      } else if (location.pathname === '/events/create/new'){
        setMenu("EventNew");
      } else if (location.pathname.startsWith('/events/create/')){
        setMenu("EventEdit");
      } else {
        setMenu("None");
      }
  }, [location.pathname, isFullyLoading])

  let renderedMenu;

  switch (menu) {
    case "Main":
      renderedMenu = <MainHeader title="Bierstriche" headheight={headerheight}/>;
      break;
    case "Events":
      renderedMenu = <MainHeader title="Events" headheight={headerheight}/>;
      break;
    case "Profile":
      renderedMenu = <SubHeader title="Profil" headheight={headerheight}/>;
      break;
    case "EventNew":
      renderedMenu = <SubHeader title="Neues Event" headheight={headerheight}/>;
      break;
    case "EventEdit":
      renderedMenu = <SubHeader title="Event update" headheight={headerheight}/>;
      break;
    case "None":
      renderedMenu = <></>;
      headerheight="0px";
      break;
    default:
      renderedMenu = <MainHeader title="Bierstriche" headheight={headerheight}/>;
  }

  return(
    <>
    <GenericHeader headerheight={headerheight}>
      {renderedMenu}
    </GenericHeader>

  </>
  )
}


export function MainHeader({title, headheight="60px", ...props}){
  const {isAuthenticated, userHasAuthenticated} = useAppContext();
    
    const { setProfile, setBierstriche, setEvents } = useProfileContext();
    //const {setContextEvent, setEventParticipants, setEventBierstriche} = useEventContext();
    const navigate=useNavigate();

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        setProfile({});
        setBierstriche([]);
        setEvents([]);
        /*setContextEvent({});
        setEventParticipants([]);
        setEventBierstriche([]);*/
        localStorage.clear();
        navigate("/login");
      }

  return (
    
    <Flex bg='yellow.300' h={headheight} pl='20px' pr='20px' zIndex={10}>
      <Center w='60px'>
      
          <Link mr='auto'  as={ReactRouterLink} to="/" textDecoration="none" _hover={{ bg: 'inherit', color: 'inherit', borderColor: 'inherit', textDecor: 'none' }}>
              <Center ><IoBeerOutline size={30}/></Center>
          </Link>
      
      </Center>
      <Spacer/>
      <Center w='200px'>
            <Heading size='lg' mb="2px" mt='2px'>{title}</Heading>
        </Center>
      <Spacer/>
      <Center w='60px'>
      
        <Menu>
          <MenuButton
            ml='auto'
            as={IconButton}
            aria-label='Options'
            icon={<IoReorderThreeOutline />}
            fontSize='2em'
            size='2xl'
            bg="yellow.300"
          />
          <MenuList >
          {isAuthenticated ? (
            <>
            <MenuItem as={ReactRouterLink} to="/profile">
                Profil
            </MenuItem>
            <MenuItem  as={ReactRouterLink} to="/events">
                Events
            </MenuItem>
            <MenuItem as={Button} onClick={()=> {handleLogout()}}>
              Logout
            </MenuItem>
            </>) : (<>
              <MenuItem as={ReactRouterLink} to="/signup">
                Anmelden
            </MenuItem>
            <MenuItem  as={ReactRouterLink} to="/login">
                Login
            </MenuItem>
            </>
            )}
          </MenuList>
        </Menu>
      
      </Center>
    </Flex>
    
    
  )
}


export const ColoredLine = ( props ) => {
    return(<hr
        {...props}
    />)
};

export function StaticBierstrichText({...props}){
    return(
        <Box pl={4} pr={4}>
            <VStack>
                {props.children ?
                    <Text mt={2}>
                            {props.children}
                    </Text>
                :<></>}
                <Heading>
                                Bierstriche.de
                </Heading>
                <Text >
                        Die einfache Konfliktprävention
                </Text>
                <Text mb={0}>
                        app.bierstriche.de
                </Text>
                <Text >
                        www.bierstriche.de
                </Text>
            </VStack>
        </Box>
    )
}